import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class CompanyHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["create", "delete", "update"];
    }
    baseURL() {
        return "company";
    }
    paginate(filter) {
        return super.paginate({
            ...filter,
        });
    }
}
const o = createInstance(CompanyHttp);
export const companyHttp = o;
export default o;
